<template>
  <v-container>
    <div>
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg2 xl2 grow pa-1 offset-lg-5 offset-xl-5>
          <span class="text-h5">
            <v-btn
              class="mx-1"
              fab
              plain
              small
              text
              color="black"
              @click="decreaseYear()"
            >
              <v-icon dark> mdi-chevron-left </v-icon>
            </v-btn>
            ปี {{ this.year + 543 - 0 }}
            <v-btn
              class="mx-1"
              fab
              plain
              small
              text
              color="black"
              @click="increaseYear()"
            >
              <v-icon dark> mdi-chevron-right </v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-layout>
    </div>
    <v-card>
      <v-card-title>
        <v-icon large> mdi-file-document </v-icon>
        รายงานผลการดำเนินงานโปรเจกต์ งาน SI & ICT Solution
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" small >
              <v-icon> mdi-help-circle </v-icon>
              เกณฑ์
            </v-btn>
          </template>
          <span
            ><strong
              >นับจำนวนโปรเจกต์ที่มีรายได้ตั้งแต่ 30,000 บาทขึ้นไป และ
              มีสัดส่วนกำไรของโปรเจกต์ ไม่ต่ำกว่า 25%</strong
            >
            <br />
            <br />
            <strong>หมายเหตุ</strong>
            <br />
            ระดับ 0 จำนวนโปรเจกต์ 0 - 4 โปรเจกต์
            <br />
            ระดับ 1 จำนวนโปรเจกต์ 5 - 6 โปรเจกต์
            <br />
            ระดับ 2 จำนวนโปรเจกต์ 7 - 9 โปรเจกต์
            <br />
            ระดับ 3 จำนวนโปรเจกต์ 10 - 12 โปรเจกต์
            <br />
            ระดับ 4 จำนวนโปรเจกต์ 13 - 14 โปรเจกต์
            <br />
            ระดับ 5 จำนวนโปรเจกต์ 15 ขึ้นไป
          </span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="SI_ICTSolutionProjectRankingDatas"
          :loading="loading"
          loading-text="Loading..."
          class="elevation-1"
          hide-default-footer
          disable-pagination
        >
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <SI_ICTSolutionProjectRankingBarChart
              :year="year"
              :officeSector="officeSector"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
  
  <script>
import axios from "axios";
import moment from "moment";
import SI_ICTSolutionProjectRankingBarChart from "./SI_ICTSolutionProjectRankingBarChart.vue";

moment.locale("th");

export default {
  name: "SI_ICTSolutionProjectRanking",
  components: {
    SI_ICTSolutionProjectRankingBarChart,
  },
  props: {},
  data() {
    return {
      year: new Date().getFullYear(),
      options: {},
      loading: false,
      SI_ICTSolutionProjectRankingDatas: [],
      officeSector: [
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "58",
      ],
      headers: [
        {
          text: "ลำดับที่",
          value: "rank",
          sortable: false,
          align: "center",
        },
        {
          text: "ส่วนบริการลูกค้า",
          value: "officeSector",
          sortable: false,
          align: "center",
        },
        {
          text: "จำนวนโปรเจกต์",
          value: "projectCount",
          sortable: false,
          align: "center",
        },
        {
          text: "ระดับ",
          value: "level",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  watch: {},
  created() {
    this.loaded = false;
    this.hasSeries = false;
    this.getDataFromAPI();
  },
  methods: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
    getDataFromAPI() {
      this.loading = true;
      const queryParams = {
        params: {
          year: this.year,
          officeSectorID: this.officeSector,
        },
      };
      axios
        .get("/report/si-ict-solution-project-ranking", queryParams)
        .then((response) => {
          // JSON responses are automatically parsed.
          // console.log(response.data);
          this.SI_ICTSolutionProjectRankingDatas = response.data;
          // this.totalPriceSumByCADatas = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    decreaseYear() {
      this.year -= 1;
      this.loaded = false;
      this.hasSeries = false;
      this.getDataFromAPI();
    },
    increaseYear() {
      this.year += 1;
      this.loaded = false;
      this.hasSeries = false;
      this.getDataFromAPI();
    },
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
