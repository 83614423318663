<template>
    <v-card>
      <div v-if="hasSeries">
        <v-chart class="chart" :autoresize="true" :option="option" />
      </div>
    </v-card>
  </template>
  
  <script>
  // import VueApexCharts from "vue-apexcharts";
  import axios from "axios";
  import * as echarts from "echarts/core";
  import {
    DatasetComponent,
    GridComponent,
    TransformComponent,
    VisualMapComponent,
    ToolboxComponent,
    TitleComponent,
    TooltipComponent,
  } from "echarts/components";
  import { BarChart } from "echarts/charts";
  import { CanvasRenderer } from "echarts/renderers";
  import VChart from "vue-echarts";
  
  echarts.use([
    DatasetComponent,
    GridComponent,
    TransformComponent,
    VisualMapComponent,
    BarChart,
    CanvasRenderer,
    ToolboxComponent,
    TitleComponent,
    TooltipComponent,
  ]);
  
  export default {
    name: "SI_ICTSolutionProjectRankingBarChart",
    props: {
      year: {
        type: Number,
        required: true,
      },
      officeSector: {
        type: Array,
        required: true,
      },
    },
    components: { VChart },
    data: () => ({
      loaded: false,
      interval: null,
      hasSeries: false,
      option: {
        dataset: [
          {
            dimensions: ["officeSector", "projectCount"],
            source: [],
          },
          {
            transform: {
              type: "sort",
              config: { dimension: "projectCount", order: "asc" },
            },
          },
        ],
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true
        },
        title: {
          left: 'center',
          text: '',
        },
        visualMap: {
          orient: 'horizontal',
          left: 'center',
          min: 0,
          max: 15,
          text: ['High', 'Low'],
          // Map the score column to color
          dimension: 1,
          inRange: {
            color: ['#FD665F', '#FFCE34', '#65B581']
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          feature: {
            saveAsImage: {
              pixelRatio: 3
            }
          }
        },
        yAxis: {
          type: "category",
          // axisLabel: { interval: 0, rotate: -45 },
        },
        xAxis: { 
          type: "value",
          axisLabel: {
            formatter: '{value}', // Ensure integer formatting
          },
          splitNumber: 10, // Adjust for better integer intervals
          minInterval: 1, // Force integer-only intervals
        },
        series: {
          type: "bar",
          encode: { x: "projectCount", y: "officeSector" },
          datasetIndex: 1,
        },
      },
    }),
    mounted() {
      this.loaded = false;
      this.hasSeries = false;
      this.getDataFromAPI();
    },
    watch: {
      year: {
        immediate: true,
        handler() {
          this.getDataFromAPI();
        },
      },
    },
    beforeDestroy() {},
    methods: {
      getDataFromAPI() {
        const queryParams = {
        params: {
          year: this.year,
          officeSectorID: this.officeSector,
        },
      };
        axios
        .get("/report/si-ict-solution-project-ranking", queryParams)
          .then((response) => {
            const result = response.data;
            let source = result.map((x) => x);
            this.option.dataset[0].source = source;
            this.option.title.text = `รายงานผลการดำเนินงานโปรเจกต์ งาน SI & ICT Solution ปี ${ this.year + 543 }`;
            this.hasSeries = result.length > 0;
            this.loaded = true;
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
          });
      },
    },
  };
  </script>
  <style scoped>
  .chart {
    height: 450px;
  }
  </style>